*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.ModalConatinerHeadingDiv{
    width: 100%;
    height: 15%;
    padding: 20px;
    background-color: #424141;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;


}

.ModalConatinerHeading{
    width: 100%;
    height:100%;
    text-align: center;
    font-size: 35px;
    font-weight: 100;
    font-family: 'Ysabeau Office', sans-serif;
    color: rgb(252, 251, 251);
}

.AgendaDeatilsConatiner{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
padding: 20px;
}

.AgendaDeatilsHeadings{
    font-size: 21px;
    width: 100%;
    height: auto;
    text-align: left;
    color: rgb(7, 7, 7);
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'Ysabeau Office', sans-serif;

}
.AgendaDetailPoints{
    font-size: 16px;
    width: 100%;
    height: auto;
    text-align: left;
    color: rgb(98, 14, 234);
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 900;
    font-family: 'Playfair Display', serif;
    padding-bottom: 20px;

}