*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.specialitiesAndTrainingsContainer{
    display: flex;
    width: 100%;
    height: 100%;
}

.specialitiesAndTrainingsContainerSpecialities{
    display: flex;
    flex-direction: column;
    width: 20%;
    height:100%;
    max-height: 800px;
    background: rgba( 255, 255, 255, 0.65 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    left: 0;    
}

.specialitiesAndTrainingsContainerTrainings{
    width: 60%;
    height:100%;

}

.specialitiesAndTrainingsContainerTrainingsRight{
    width: 20%;
    height:100%;
}

.specialitiesAndTrainingsContainerSpecialitiesHeadingDiv{

    display: flex;
padding: 20px;
justify-content: center;
width: 100%;
align-items:center ;

}

.actualSpecialitiesContainer{
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
padding: 10px;
overflow-y: scroll;
direction: rtl;

}

.actualSpecialitiesContainer::-webkit-scrollbar-thumb::before{
    color: teal; /* Color of the scrollbar thumb (the moving part) */
  }
  
  div::-webkit-scrollbar-thumb:hover {
    background-color: transparent; /* Color of the scrollbar thumb on hover */
  }
.specialitiesAndTrainingsContainerSpecialitiesHeadingDivHeading{
    font-size: 24px;
    width: 100%;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 30px;
   
}

.actualSpecialitiesContainerP{
    width: 96%;
    font-size: 21px;
    padding: 20px;
    text-align: center;
    font-family: 'Lato', sans-serif;
    color:rgb(6, 6, 6);
    font-weight: 900;
    background: rgba(248, 249, 249, 0.45);
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
margin: 5px;
}