*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.parentForHomePageContainer{
    width: 100%;
    height: 100%;
}
.homepageContainer{
    background: url(https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFinalHomepageIMGGGG.png?alt=media&token=e98938c3-59f2-404d-87c0-622242fab7cc);
    overflow: hidden;
    background-repeat: no-repeat, no-repeat;
    background-size:cover;
    height: 834px;
    /* background-attachment: scroll; */
    width: 100%;
}
.heroSectionContainer{
    /* background-image: url(../images/Desktop\ -\ 2.png);
     */
     
    height: 100%;
    width: 100%;
    
}
.navbarContainer{
   height: 0%;
   width: 100%;
}
  
.mainHeroSection{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    height: 100%;
    position: relative;
}
.leftMainHeroSection{
    grid-column-start: 1;
grid-column-end: 2;
width: 100%;

}
.headingOfLeftMainheroSection{
    position: absolute;
    left: 50px;
    top: 225px;
    font-size: 50px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}

.missionStatement{
    position: absolute;
    left: 115px;
    top: 220px;
    font-size: 25px;
    width: 400px;
    height: 226px;
    line-height: 30px;
    color: #192551;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

.typingSpan{
    color: white;
    font-size: 50px;
    position: absolute;
    width: 400px;

    left: 115px;
    top: 300px;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}
.rightHeroSectionImg{
    height: 650px;
    width: 700px;
    position: absolute;
    top: 80px;
    left: 700px;
    /* box-shadow: 10px 10px ; */

}

@media (max-width: 480px) {
    .webinarCardsResponsiveCarousel{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .parentForHomePageContainer{
        height: 100%;
        /* width: 480px; */
    
    }
    .homepageContainer{
        height: 215px;
        width: 100%;
        background-size: contain;
    }
    .navbarContainer{
        height: 20%;
        width: 100%;
    }
    .leftMainHeroSection{
position: relative;
display: flex;
justify-content: center;
align-items: center;

    }
    .missionStatement{
position: absolute;
top: 30px;
left: 15px;
font-size: 14px;
font-weight: 100;
width: 50%;
height: 50%;
line-height: 15px;

    }
    .typingSpan{
        position: absolute;
        top: 100px;
        left: 15px;
        font-size: 12px;
        width: 40%;
    }
}

