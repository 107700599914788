
.blogPageContainer{
    width:100%;
    height:100%;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

}
.offerAboveNavbar{
    width: 100%;
    height: 50px;
    background-color: #263238;
    display: flex;
    justify-content: center;
    align-items: center;
}
.offerAboveNavbar2{
    width: 120px;
    height: 100%;
    background-color: #263238;
    display: flex;
    justify-content: center;
    align-items: center;
}
.offerAboveNavbarP{
    color: white;
    font-family: 'Jost', sans-serif;
font-size: 21px;

}
.AllFoldsBlogPageContainer{
    width:60%;
    height:100%;
    background-color: #F4F1E9;


}
.AllFoldsBlogPageContainerNewSDOHPage{
    border-radius: 10px;
    width: 80%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

}
.secondFoldNewSDOHPage{
    width:100%;
    height: 100%;
    display: flex;
}
.secondFoldNewSDOHPageLeft{
    width:70%;
   flex: 1;

}
.scrollable-content{
    overflow-y: auto;
    max-height: 865px; /* Set your desired maximum height here */
}
.secondFoldNewSDOHPageRight{
    width:30%;
    height: 100%;
    position: sticky;
    background: rgba( 255, 255, 255, 0 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.secondFoldNewSDOHPageRightCoffeeContainer{
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.secondFoldNewSDOHPageRightCoffeeContainerh3{
    font-size: 32px;
    text-align: center;
    width: 94%;
    margin-top: 25px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;

}
.secondFoldNewSDOHPageRightCoffeeContainerHeading{
    font-family: 'Jost', sans-serif;
    font-size: 21px;
    width: 100%;
    height: 30%;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
}

.secondFoldNewSDOHPageRightCoffeeContainerPrice{
    font-family: 'Jost', sans-serif;
    font-size: 48px;
    color: darkgreen;
    width: 100%;
 
    text-align: center;
    margin-bottom: 5px;

}
.secondFoldNewSDOHPageRightCoffeeContainerPrice2{
    font-family: 'Jost', sans-serif;
    font-size: 32px;
    color: darkgreen;
    width: 100%;
 
    text-align: center;
    margin-bottom: 5px;

}

.secondFoldNewSDOHPageRightCoffeeContainerImg{
    width: 25%;
    border-radius: 10px;
    height: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;

}
.secondFoldNewSDOHPageRightRegistrationHeading{
    font-size: 18px;
    font-family: 'Ysabeau Office', sans-serif;
    text-align: center;
    /* margin-left: 10px; */
  
    width: 100%;
}
.emailInputANdlineDivSDOHp{
    font-size: 18px;
    font-family: 'Ysabeau Office', sans-serif;
text-align: left;
}
.Astrix{
    width: 100%;
    font-family: 'Jost', sans-serif;
    padding-left: 40px;
    font-size: 12px;
 
}
.secondFoldNewSDOHPageRightRegColumn{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: flex-start;
    background:rgb(255, 255, 255);

backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );

border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.emailInputANdlineDivSDOH{
    padding-left: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.WebinarInfoCubeDetailsContainer{
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    margin-bottom:20px;
    flex-direction: column;
    align-items: flex-end;
}
.WebinarInfoCubeDetailsContainerResponsive{
    display: none;
}
.WebinarInfoCubeDetails{
    width: 100%;
    height: 100%;
 
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.classForm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

}
.WebinarInfoCube{
    width: 120px;
height:120px;
display: flex;
flex-direction: column;
background-color: #6664DA;
border-radius: 10px;
}
.WebinarInfoCube2{
    width: 45%;
height:100%;
flex-direction: column;
display: flex;
justify-content: center;
background-color: white;

}
.Free{
width: 100%;
height: 100%;
font-size: 28px;
font-family: 'Jost', sans-serif;
margin-top: 10px;
text-align: right;

}
.starbucksCoffeeImageSDOH{
    width: 120px;
    height: 120px;
    border-radius: 10px;
    box-shadow: 2px 2px black;
}

.aapcCEUImage{
    width: 100%;
    height: 100%;
}
.WebinarInfoCubeIconDiv{
    width: 100%;
    height:50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.WebinarInfoCubeP{
    font-size: 21px;
    font-family: 'Ysabeau Office', sans-serif;
width: 100%;
height: 50%;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
color: white;
}
.WebinarInfoCubeP2{
    font-size: 16px;
    font-family: 'Ysabeau Office', sans-serif;
width: 100%;
height: 50%;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
color: white;
}
.sessionHighlightsP{
    font-family: 'Jost', sans-serif;
    font-size: 21px;
    color: black;
}
.WebinarInfoCubePrice{
    font-size: 32px;
    font-family: 'Ysabeau Office', sans-serif;
width: 100%;
height: 100%;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
}
.firstFoldBlogPageContainer{
width: 100%;
height:100%;
display: flex;
justify-content: left;
align-items: center;
flex-direction:column;
}
.firstFoldContainerh2{
    width:100%;
    height:100%;
text-align: center;
    font-size:48px;
    margin-top:10px;
    font-weight:bold;
    color: #6664DA;
}
.newFirstFoldContainerWebinar{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
   
    margin-bottom: 20px;
}
.newFirstFoldContainerWebinarLeftdetails{
    width: 100%;
    height: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: left;

    flex-direction: column;
}
.newFirstFoldContainerWebinarLeftdetailsH2{
    font-size: 28px;
    width: 100%;
    text-align: left;
    font-family: 'Jost', sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;

}
.newSecondFoldWebinarContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.newSecondFoldWebinarContainerLeft{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.newSecondFoldWebinarContainerRight{
    width: 40%;
    height: initial;
    display: flex;
    justify-content: center;
    align-items: center;
}
.newSecondFoldWebinarContainerRightJoinNowContainer{
    width: 100%;
    height: 60%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 2px 2px 2px 2px #6664DA;
}
.newSecondFoldWebinarContainerRightJoinNowContainerLeft{
    width: 45%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba( 126, 211, 33, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.newSecondFoldWebinarContainerRightJoinNowContainerRight{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.newAapcImageConatiner{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.newAapcImage{
    width: 20%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom-right-radius: 2%;
    box-shadow: 2px 2px #6664DA;
}
.priceSDOH{
    font-size: 48px;
    color: white;
    font-family: 'Jost', sans-serif;
margin-top: 20px;
margin-bottom: 20px;
background-color: #6664DA;
width: 20%;
text-align: center;
border-radius: 5px;
}
.newFirstFoldContainerWebinarRight{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding-top: 150px;
}
.newFirstFoldContainerWebinarRightSpeakerImageContainer{
    border-top-left-radius: 40%;
    border-bottom-right-radius: 40%;
    box-shadow: 5px 5px #6664DA;
}
.newFirstFoldContainerWebinarLeftContainer{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.newFirstFoldContainerWebinarRightSpeakerDeatils{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.newFirstFoldContainerWebinarRightSpeakerDeatilsH1{
    font-family: 'Jost', sans-serif;
    text-align: center;
    margin-top: 10px;

}
.newFirstFoldContainerWebinarRightSpeakerImage{
    width: 98%;
    height: 100%;
    border-top-left-radius: 40%;
    border-bottom-right-radius: 40%;
}
.newFirstFoldContainerWebinarLeft{
    width: 100%;
    height: 100%;
}
.firstFoldContainerh3{
    width:88%;
    height:100%;
    margin-left:20px
}
.firstFoldContainercontent{
    width:75%;
    height:100%;

    font-size: 18px;   
}
.FreeTialContainerBlogPage{
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.firstFoldContainerh4{
    width:74%;
    height:100%;

    font-weight:bold;
    font-size:32px;
    margin-bottom:20px;
}
.firstFoldContainerFreeTrialH4{
    width:74%;
    height:100%;
text-align: center;
    font-weight:bold;
    font-size:32px;
    margin-bottom:20px;
}


.firstFoldContainerImgDiv{
width:100%;
display:flex;
justify-content: center;
align-items:center;

margin-bottom: 20px;
}


.firstFoldContainerImg{
    width: 90%;
  
    height: 60%;
    border-radius: 10px;
    border: 0.5px solid rgb(234, 232, 232);
    background: rgba( 255, 255, 255, 0 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.firstFoldContainerImgWithoutGlass{
    width: 75%;
height:60%;
border-radius:10px;
}
.horizontalLine{
    height: 5px;
    border-radius: 1px;
    width: 90%;
    background-color: #6664DA;
    margin-top: 5px;
}
.horizontalLine1{
    height: 5px;
    border-radius: 1px;
    width:90%;
background-color: black;
margin-top: 20px;
margin-bottom: 10px;
}

.footerSDOH{
    width: 100%;
}
@media  (max-width: 480px) {

    .offerAboveNavbar{
        width: 109%;
    }
    .offerAboveNavbarP{
        text-align: center;
        font-size: 18px;
    }
    .newFirstFoldContainerWebinar{
        width: 109%;
        flex-direction: column;
    }
    .newFirstFoldContainerWebinarLeftContainer{
        width: 100%;
    }
    .newFirstFoldContainerWebinarLeftdetails{
        align-items: center;
    }
    .newFirstFoldContainerWebinarLeftdetailsH2{
        text-align: center;
    }
    .newAapcImageConatiner{
        justify-content: center;
    }
    .newAapcImage{
        width: 40%;
    }
    .priceSDOH{
        width: 40%;
    }
    .newFirstFoldContainerWebinarRight{
        width: 98%;
        padding-top: 20px;
    }
    .newSecondFoldWebinarContainer{
        width: 109%;
        flex-direction: column;
    }
    .newSecondFoldWebinarContainerLeft{
        width: 100%;
    }
    .newSecondFoldWebinarContainerRight{
        width: 100%;
    }
    .footerSDOH{
        width: 109%;
    }
    .secondFoldNewSDOHPage{
        flex-direction: column-reverse;
    }
    .secondFoldNewSDOHPageRight{
        width: 100%;
    }
    .secondFoldNewSDOHPageLeft{
        width: 100%;
    }
    .scrollable-content{
        overflow-y: scroll;
    }
.firstFoldContainerImgDiv{
    justify-content: center;
    padding-top: 20px;
}
.firstFoldContainerImg{
    margin-right: 0px;
}
.WebinarInfoCubeDetailsContainerResponsive{
    width: 109%;
    height:100%;
    display: flex;
    justify-content: center;
    margin-bottom:20px;
    flex-direction: column-reverse;
    align-items: center;
}
.WebinarInfoCubeDetailsContainer{
    display: none;
}
.WebinarInfoCubeDetails{
    width: 100%;
    height: 100%;
 margin-right: 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

}
.Astrix{
    width: 100%;
    font-family: 'Jost', sans-serif;
    padding-left: 30px;
    font-size: 12px;
}
.secondFoldNewSDOHPageRightCoffeeContainerh3{
    margin-top: 0px;
}
.WebinarInfoCubeDetails1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 75%;
}
.WebinarInfoCubeDetails2{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 75%;
}
    .blogPageContainer{
        width:109%
    }
    .firstFoldContainerh2{
        font-size: 32px;
    }
    .firstFoldContainerh4{
        font-size:21px;
    }
    .AllFoldsBlogPageContainer{
        width:100%;
    }
}
