*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.unsubscribeContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 375px;
    width: 75%;
    margin: auto;
    
}

.unsubscribeheading{
    font-size: 18px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
text-align: center;
width: 100%;
}