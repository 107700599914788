@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-source-sans-pro: "Source Sans Pro";

  /* font sizes */
  --font-size-base: 32px;
  --font-size-lg: 96px;

  /* Colors */
  --color-gray-100: #fffefe;
  --color-gray-200: #fffcfc;

  /* border radiuses */
  --br-md: 30px;
}
