*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}

.chatgptWebinarBanner{
    width: 100%;
    margin-top: 78px;
    height: 100%;
    display: flex;
    background: rgba( 255, 255, 255, 0.65 );
    /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    justify-content: center;
    flex-direction: column;

}
.chatgptWebinarBannerFirstSection{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    
}
.chatgptWebinarBannerSecondSection{
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
}
.chatgptWebinarBannerLeft{
    width: 46%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
   
    align-items: center;
    padding: 40px;

}
.chatgptWebinarBannerLeftSmallHeading{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
   color:gray;
   margin: 10px;
}

.chatgptWebinarBannerLeftHeading{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    font-size: 24px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
   margin: 10px;

}

.chatgptWebinarBannerLeftSubHeading{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    font-size: 21px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
   margin: 10px;
}

.speakerCircles{
    width: 60%;
 height: 100%;
  
   margin: 10px;
   position: relative;
}

.speakerCirclesImage1{
    width: 10%;
    height: 10%;
    top: 5%;
    left: 0%;
    position: inherit;

}
.speakerCirclesImage2{
    border-radius: 50%;
    width: 10%;
    height: 10%;
    top: 5%;
    left: -6%;
    position: inherit;
}
.speakerCirclesImage3{
    border-radius: 50%;
    width: 10%;
    height: 10%;
    top: 5%;
    left: -10%;
    position: inherit;

}
.speakerCirclesImage4{
    border-radius: 50%;
    width: 10%;
    height: 10%;
    top: 5%;
    left: -14%;
    position: inherit;
}

.speakerCirclesHeading{

    font-size: 21px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    width: 100%;
    text-align: left;
    height: 10%;
    top: 5%;
    right: 0;
   
    position: inherit;
}

.chatgptWebinarBannerRight{
    width: 23%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    border: 3px solid teal;
    border-radius: 10px;
}

.emailInputANdlineDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.checkBoxDiv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatgptWebinarBannerRightHeadingDiv{
    width: 100%;
    padding: 5px;
    height: 100%;

}

.chatgptWebinarBannerRightHeading{
    font-size: 24px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    text-align: right;
    width: 65%;
    padding-right: 20px;

}
.Enprompt360OnKickstarterDiv{
    width: 100%;
    height: 100%;
    display:flex;
    /* background: rgba( 255, 255, 255, 0.4 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 ); */
align-items: flex-start;
padding: 6px;
}

.Enprompt360OnKickstarterDivHeadingDiv{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: right;
    padding-right: 20px;
    align-items: center;
}

.logoAndNameConatiner{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
}

.Enprompt360OnKickstarterDivProjectURLImageContainer{
    padding: 20px;
    width: 73%;
    height: 100%;
    display:flex;
    justify-content: flex-start;

    align-items: center
    ;
}
.Enprompt360OnKickstarterDivProjectURLImageContainerP{
    text-align: left;
    font-size: 22px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    height: 17%;
    width: 100%;
}

.Enprompt360OnKickstarterDivProjectURLImageContainerImg{
    width: 40%;
    height: 90%;
    margin: 5px;
    border-radius: 10px;

}
.Enprompt360OnKickstarterDivProjectURLImageContainerInfoANdKickastarterImage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.KickstarerLogo{

    height: 41%;
    width: 29%;
   
}

.Enprompt360OnKickstarterDivProjectURLImageContainerKickstarterInfo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    height: 100%;

}

.Enprompt360OnKickstarterDivProjectURLImageContainerInfo{
    text-align: left;
    font-size: 16px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    height: 50%;
    width: 100%;
    text-align: center;
    /* padding-left: 59px; */
    margin: 0 auto;
}
.chatgptWebinarBannerLeftInfo{
    margin-top: 48px;
    font-size: 20px;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    width: 60%;
    text-align: left;
    height: 10%;
    top: 5%;
    right: 0;
    position: inherit;
    margin: 10px;

    }
.chatgptWebinarBannerRightBelow{
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgba( 255, 255, 255, 0.4 );
    border-bottom: 1px solid teal;
 
}
.firstFoldInfoContainerButtonDivChatGptWebinarBanner{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    
    display: flex;
    justify-content: right;
    align-items: center;
    /* margin-right: 30px; */
}

.firstFoldInfoContainerButtonDivChatGptWebinarBannerButton{
    width: 150px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 3px 3px rgb(124, 200, 235);
transition: 0.7s;
    background-color: rgb(249, 249, 249);
    border: 1px solid #7E4163;
    border-right: 1px solid #7E4163;

    color: #7E4163;
    /* margin-left: 10px; */
    font-size: 18px;
    /* border: 1px solid white; */
    font-family: 'Ysabeau Office', sans-serif;
font-weight: 700;
}
.chatgptWebinarBannerRightBelowFirstP{
    color: #7E4163;
    /* margin-left: 10px; */
    font-size: 18px;
    /* border: 1px solid white; */
    font-family: 'Ysabeau Office', sans-serif;
font-weight: 700;
text-align: right;
width: 100%;

}
.fifthFoldInfoContainerButton1:hover{
    width: 150px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 4px 4px  rgb(148, 119, 236);
    background-color: rgb(205, 81, 196) ;
    color: rgb(1, 1, 15);
    /* margin-left: 10px; */
    font-size: 18px;
    border: 1px solid rgb(112, 112, 112);
    font-family: 'Ysabeau Office', sans-serif;
font-weight: 700;
}
.chatgptWebinarBannerRightBelowHeading{
    width: 100%;
    height: 100%;
    font-size: 32px;
text-align: center;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    padding-top: 5px;
    text-align: right;
}

.chatgptWebinarBannerRightBelowP{
    width: 100%;
    height: 100%;
    font-size: 21px;
text-align: left;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    margin-top: 10px;
 
}

.chatgptWebinarBannerRightBelowPBelowdiv{
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.chatgptWebinarBannerRightBelowPBelowDivP{
    width: 100%;
    height: 100%;
    font-size: 20px;
    text-align: left;
    /* padding-right: 5px; */
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;

}

.chatgptWebinarBannerRightBelowPBelowDivPComingSoon{
    width: 100%;
    height: 100%;
    font-size: 18px;
/* padding-left: 5px; */
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    text-align: center;
    color: gray;
}

.chatgptWebinarBannerRightBelowButtonDiv{

}
