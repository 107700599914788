*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.webinarSliderContainer{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
   

    
    
}
.webinarSliderContainerr{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 700px;
    margin-top: 75px;
}
.upcomingtrainingsHeading{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 30px;
text-align: center;
width: 100%;
height: auto;
margin-top: 50px;
}
.allWebinarsContainer{
    display: flex;
    justify-content: space-evenly;
  
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 40px;
}
.firstWebinar, .secondWebinar , .thirdWebinar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 400px;
    width: 30%;
    border-radius: 20px;
    border: 10px black;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame66.png?alt=media&token=b156709f-3af7-44a7-a78b-5f5484ba4f71);
    /* margin: 45px; */
    margin-bottom: 20px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.247);
}
.firstFutureWebinar, .secondFutureWebinar , .thirdFutureWebinar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 225px;
    width: 28%;
    border-radius: 20px;
    border: 10px black;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame66.png?alt=media&token=b156709f-3af7-44a7-a78b-5f5484ba4f71);
    /* margin: 45px; */
    margin-bottom: 20px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.247);
}
.firstWebinar{
/* margin-left: 100px; */
}
.forresponsivenessFirstWebinar{

}
.thirdWebinar{
    /* margin-right: 100px; */
}

.topImageOfWebinar{
    height: 100px;
    display: flex;
    justify-content: flex-start;
  width: 100%;
  position: relative;
  flex-direction: column;
  

}
.topImageOfFutureWebinars{

    display: flex;
    justify-content: flex-start;
  width: 100%;
  position: relative;
  flex-direction: column;
  

}
.topImageOfWebinar2{
    height: 100px;
    display: flex;
    justify-content: flex-start;
  width: 45%;
  position: relative;
  flex-direction: column;
}
.topImagesize{
    height: 85px;
    width: 100%;
    border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.topImagesize2{
    height: 85px;
    width: 100%;
  border-radius:20px ;
}
.webinarSpeakerInfoImg{
    height: 80px;
    width: 20%;
    position: absolute;
    left: 30px;
    top: 40px;
    z-index: 100;
    border-radius: 50%;
}
.WebinarHeadingAndInfo{
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;


}
.futureWebinarHeadingAndInfo{
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;


}

.webinarHeading{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 400;
font-size: 24px;
text-align: left;
width: 86%;
margin-top: 20px;

text-align: left;


}
.webinarHeadingActual{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 28px;
text-align: left;
width: 86%;
margin-top: 10px;
text-align: left;
}
.futureWebinarHeadingActual{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 22px;

width: 100%;
text-align: center;
}
.LearnMoreButton{
    width:100px;
    height:40px;
    background: rgba( 255, 255, 255, 0 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
font-weight:600;
border: 1px solid rgba( 255, 255, 255, 0.18 );
color: #192551;

}

.webinarHeadingActual2{
    color: #192551;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    padding: 20px;
    /* margin-top: 40px; */
    width: 100%;
    position: absolute;
}

.webinarTagline{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
font-size: 14px;
width: 80%;
padding-top: 20px;
text-align: left;

}

.webinarSpeakerInfo{
    height: 130px;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

width: 100%;

}

.nameOfSpeakerHeading{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 16px;
width: 80%;
text-align: center;
}
.nameOfSpeakerHeadingMarsha{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 20px;
width: 80%;
text-align: Left;
margin-left:34px
}

.nameOfSpeakerCertification{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 14px;
width: 80%;
text-align: center;
text-decoration: underline;
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
  
  }

  .mySwiper{
    display: none;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .firstFutureWebinar, .secondFutureWebinar , .thirdFutureWebinar{
        width: 95%;
    }
    .mySwiper{
        display: block;
        height: 430px;
      }
      .allWebinarsContainer{
        display: none;
      }

    .firstWebinar, .secondWebinar , .thirdWebinar{
        width: 95%;
        height: 350px;
    }

    .WebinarHeadingAndInfo{
        height: 327px;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
    }

  }

