*{
    margin:0;
    padding:0;
    box-sizing:border-box;

}

.ThankyoupageConatiner{
    width:100%;
    height:100%;
    background-color:#FBF7EC;
    
}
.ThankyoupageConatinerImgDiv{
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:30px;
    
}

.ThankyoupageConatinerImg{
    width:35%;
    height:70%;

}

.ThankyoupageConatinerHeading{
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    width:100%;
    height:100%;
    text-align:center;
    margin-bottom:20px;
}

.ThankyoupageConatinerHeading2{
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    width:100%;
    height:100%;
    text-align:center;
    margin-bottom:20px;
    font-weight:100;
}
.ThankyoupageConatinerOtherWebinarsDiv{
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
}