*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.allScreenshotsContainer{
    height: 700px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    background-size: cover;
}
.leftAdminPanelSec{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 67%;
    /* margin-right: 50px; */
}
.leftAdminPanelImgContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88%;
    width: 83%;
    border-radius: 5px;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame66.png?alt=media&token=b156709f-3af7-44a7-a78b-5f5484ba4f71);
    /* background-color: grey; */


}
.bottomAdminPanelImg{
    height: 424px;
    width: 621px;

}
.rightAdminPanelSec{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    height: auto;

}
.rightAdminPanelHeading{
    font-size: 22px;
    font-family: 'GFS Didot', serif;
        font-family: 'Lato', sans-serif;
        font-weight: 900;
     color: #00254F;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    
  }

  .rightAdminPanelTagline{
    font-size: 16px;
    font-family: 'GFS Didot', serif;
        font-family: 'Lato', sans-serif;
        font-weight: 100;
     color: #00254F;
     margin-top: 20px;
     line-height: 40px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .allScreenshotsContainer{
        height: 520px;
        flex-direction: column-reverse;
        position: relative;

    }
    .rightAdminPanelSec{
        position: absolute;
    top: 30px;
    /* left: 120px; */
    width: 100%;
    }
    .leftAdminPanelSec{
        position: absolute;
        /* left: 117px; */
        bottom: 118px;
        /* display: block; */
        height: 158px;
        width: 252px;
    
    }
    .leftAdminPanelImgContainer{
        height: 167%;
        width: 121%;
    
    }
    .rightAdminPanelHeading{
        font-size: 14px;

    }
    .rightAdminPanelTagline{
        font-size: 12px;
        line-height: 20px;

    }
    .bottomAdminPanelImg{
        height: 270px;
        width: 300px;
    }
    .swiper-slide img {
        /* width: 200%;
    height: 164%; */
  }

    }
    
  