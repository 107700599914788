.ourSpecialitiesContainer{
    width:100%;
    height:100%;
    display:flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;

}

.ourSpecialitiesContainerHeading{
    width: 100%;
    height: 20%;
    text-align: center;
    font-size: 35px;
    font-family: 'Playfair Display', serif;
    font-weight: 900;

    padding-top: 20px;
    
}
.ourSpecialitiesContainerSpecialtiesDiv{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
margin-top: 30px;
background: rgba( 69, 141, 230, 0.45 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
width: 80%;
padding: 20px;
}

.ourSpecialitiesContainerSpecialtiesDivRow{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;

}
.ourSpecialitiesContainerSpecialtiesDivRowP{
    width: 100%;
    font-size: 20px;
    font-family: 'Playfair Display', serif;
    text-align: center;
   

}