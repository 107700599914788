*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.ModalConatinerHeadingDiv{
    width: 100%;
    height: 15%;
    padding: 20px;
    background-color: #424141;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;


}
.SpeakerModalContainer{
    width: 53%;
    height: 80%;
    top: 20%;
    left: 35%;
    transform: translate(-20%,-12%);
    position: fixed;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    /* overflow-y: scroll; */
    padding-bottom: 20px;
    z-index: 100;
}
.SpeakerModalContainerSusanModal{
    width: 53%;
    height: 80%;
    top: 20%;
    left: 35%;
    transform: translate(-20%,-12%);
    position: fixed;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 20px;
    z-index: 100;
}
.ModalConatinerHeading{
    width: 100%;
    height:100%;
    text-align: center;
    font-size: 35px;
    font-weight: 100;
    font-family: 'Ysabeau Office', sans-serif;
    color: rgb(252, 251, 251);
}

.ModalSpeakerImgAndName{
    width: 100%;
    height: 30%;
    display: flex;
    
}

.ModalSpeakerNameAndCertification{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
}
.ModalSpeakerNameAndCertificationName{
    width: 100%;
    font-size: 60px;
font-family: 'Playfair Display', serif;
font-weight: 900;
padding-left: 20px;
padding-top: 20px;
padding-bottom: 10px;
}
.ModalSpeakerInfo{
    width: 100%;
    height: 70%;
    display: flex;
    padding-top: 70px;
    padding-right:70px ;
    padding-bottom: 70px;
    padding-left: 30px;
}
.ModalSpeakerInfoP{
    width: 100%;
    font-size: 15px;
font-family: 'Playfair Display', serif;
font-weight: 100;
padding-top: 36px;

}
.ModalSpeakerInfoPSusanModal{
    width: 100%;
    font-size: 15px;
font-family: 'Playfair Display', serif;
font-weight: 100;
padding-top: 36px;
padding-bottom: 40px;
}
.ModalSpeakerNameAndCertificationCertification{
    width: 100%;
    font-size: 15px;
font-family: 'Playfair Display', serif;
font-weight: 900;
padding-left: 26px;
}
.ModalSpeakerNameAndCertificationSpeakerImage{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.ModalSpeakerNameAndCertificationSpeakerImageImg{
    width: 38%;
    height: 90%;
    border-radius: 10px;
    margin-top: 40px;
    margin-right: 40px;
}
.AgendaDeatilsConatiner{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
padding: 20px;
}

.AgendaDeatilsHeadings{
    font-size: 21px;
    width: 100%;
    height: auto;
    text-align: left;
    color: rgb(7, 7, 7);
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'Ysabeau Office', sans-serif;

}
.AgendaDetailPoints{
    font-size: 16px;
    width: 100%;
    height: auto;
    text-align: left;
    color: rgb(98, 14, 234);
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 900;
font-family: 'Playfair Display', serif;

}

@media (max-width: 480px) {

.SpeakerModalContainerSusanModal {
    width: 95%;
    height: 90%;
    top: 16%;
    left: 21%;
    transform: translate(-20%,-12%);
    position: fixed;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    /* padding-bottom: 20px; */
    z-index: 100;
}
}