*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.FooterContainer{
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    flex-direction: row;
    margin-top: 30px;
    background-color:beige;
}
.FooterLogo{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 16px;
}
.FooterLogoInfo{
    margin-top: 15px;
    width: 70%;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 12px;
}
.footerAddress{
    margin-top: 15px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 12px;
}
.footerAddressIcon{
    margin-top: 15px;
    /* width: 70%; */
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 12px; 
position: absolute;
top: 2px;
left: 30%;
}
.linksToPrivacyAndTerms{
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
font-size: 12px;
}
.ActualinkToTermsAndPrivacy{
    text-decoration: none;
    color: #192551;
    cursor: pointer;
}
.footerLogoSection{
width: 30%;
margin-left: 100px;

}

.footerLogoMenu{
    width:30%;
    margin-left: 100px;

}
.FooterHome{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
font-size: 16px;
width: 30%;
line-height: 30px;
}
.FooterContactUs{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
font-size: 16px;
}
.footerLMS{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
font-size: 16px;
}

.footerWebinars{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
font-size: 16px;
}

.termsAndConditions{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
font-size: 16px;
}

.privacy{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
font-size: 16px;
}
.footermainlingList{
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
font-size: 16px;
}

.emailAdressFooterEmailingList{
height: 30px;
width:180px;

border-radius: 5px;
/* padding-left: 10px; */
/* margin-left: 25px; */
border-color: #192551;
margin-top: 5px;
padding-left: 5px;
/* margin-right: 300px; */
}
.successMsgForEmailingList{
    height: 50px;
    width: 190px;
    color: #192551;
    border-radius: 10px;
    box-shadow: 2px 2px black;font-size: 12px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 900;
    padding: 5px;
    text-align: center;
    background: rgba( 141, 125, 125, 0.45 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 9px );
-webkit-backdrop-filter: blur( 9px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.SubmitButtonForEmailList{
    margin-left: 10px;
    height: 20px;
    width: 50px;
    border-radius: 5px;
    border-color: #192551;
    color: white;
    background-color: #192551;
    font-size: 10px;
}
.SubmitButtonForEmailList:hover{
border-color: white;
}
.footerLogoImgSize{
        height: 120px;
    width: 150px;
    margin-top: 20px;
}
.adressforFooter{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
@media (min-width: 320px) and (max-width: 480px) {

    .adressforFooter{
    justify-content: center;

    }
    .FooterContainer{
        justify-content: flex-start;
        flex-direction: column;
        height: 500px;
        align-items: center;
        padding-top: 20px;
        margin-top: 40px
    }
    .footerLogoSection{
        width:100%;
        margin-left: 0px;
        text-align: center;
    }
    .FooterLogoInfo{
        width: 100%;

    }
    .linksToPrivacyAndTerms{
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .footerLogoMenu{
        width: 100%;
        margin-left: 0;
        text-align: center;
        margin-top: 10px;
    }
    .footerLogoImgSize{
        
        width: 100px;
    }
    .FooterHome{
        width: 100%;

    }
    .footermainlingList{
        text-align: center;
        margin-top: 10px;
    }
    .joinMailingList{
        padding-right: 0px;
    }
}