*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbarComponentContainer{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
height: 70px;
/* background-color: aqua; */
position: sticky;
z-index: 100;
}
.navbarComponentOtherContainer{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
height: 70px;
/* background-color: aqua; */
position: sticky;
z-index: 100;
}
.navItemLogo{
    place-self: center;
    font-size: 25px;
    color: #00254F;
    font-weight: 900;

}
.navItemLogo:hover{
    color: white;
    text-decoration: underline;
    cursor: pointer;
    
   
    border: 2px;
    
}
.navItemWebinars{
    place-self: center;
    
grid-column-start: 6;
grid-column-end: 7;
/* padding-left: 100px; */
font-size: 14px;
color: #00254F;
}

.navItemLms{
    place-self: center;
    grid-column-start: 5;
    grid-column-end: 6;
    padding-left: 100px;
    font-size: 14px;
    color: #00254F;



}
.navItemLms:hover{
    color: white;
    text-decoration: underline;
    cursor: pointer;

    border: 2px;
}
.navItemSpeaker{
    place-self: center;
    grid-column-start: 4;
    grid-column-end: 5;
    padding-left: 100px;
    font-size: 14px;
    color: #00254F;


}
.navItemSpeaker:hover{
    color: white;
    text-decoration: underline;
    cursor: pointer;

    border: 2px;
}
.navItemHome{
    place-self: center;
    grid-column-start: 3;
    grid-column-end: 4;
    padding-left: 100px;
    font-size: 14px;
    color: #00254F;

}
.navItemHome:hover{
    color: white;
    text-decoration: underline;
    cursor: pointer;

    border: 2px;
}
.menuClickNavbar{

font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 24px;
    border-radius: 5px;
    background: rgba( 0, 202, 202, 0.25 );
box-shadow: 0 0px 0px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 5px );
-webkit-backdrop-filter: blur( 20px );
border: 1px solid rgba( 255, 255, 255, 0.18 );
    /* color:white; */
   
    /* box-shadow: 2px 2px 2px white ; */
    cursor: pointer;
    position: relative;
    
}
.footerLogoImgSizeNavbar{
    height: 120px;
    width: 150px;
    margin-left: 30px;
}
.menuClickNavbar:hover{
background-color: white;
color: #00254F;
border-radius: 5px;
width: 50px;
height: 40px;
}
.hamburgerMenu{
position: absolute;
height: 230px;
width: 150px;
background-image: url(https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame66.png?alt=media&token=b156709f-3af7-44a7-a78b-5f5484ba4f71);
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px);
box-shadow: 0 8px 32px rgba(0,0,0,0.37); 
border-radius: 10px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
right: 101px;
top: 95px;
z-index: 100;
}
.footerLogoImgSizeNavbar{
    height: 120px;
    width: 150px;
    margin-top: 20px;
}
.hamburgerWebinars{
width: 150px;
height: 30px;
font-size: 16px;
font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00254F;
    margin-top: 20px;
    border: 1px #00254F;
    
    



}
.hamburgerWebinars:hover{
    background-color: white;
    border-radius: 5px;
    color: black;
    cursor: pointer;

}
.hamburgerSignUpButton{
    width: 150px;
height: 30px;
font-size: 16px;
font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00254F;
    margin-top: 20px;
    border: 1px #00254F;
    cursor: pointer;
}
.hamburgerSignUpButton:hover{
cursor: pointer;
}
.signUpButtonInNavabarHamburgerMenu{
background-image: url(https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame66.png?alt=media&token=b156709f-3af7-44a7-a78b-5f5484ba4f71);
    
    height: 40px;
    width: 80px;
    border-radius: 5px ;
    font-size: 16px;
font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
 color: #00254F;
    cursor: pointer;
    border-color: #F4BDAB;

}
.becpmeASpeakerRoute{
    text-decoration: none;
    color: #00254F;

}
.signUpButtonInNavabarHamburgerMenu:hover{
    height: 40px;
    width: 100px;
    /* background-image: url(../images/SignUpButton.png); */
    color: white;
    border-color: white;
    
}
.hamburgerWebinarsLink{
    text-decoration: none;
 color: #00254F;


}
@media (min-width: 320px) and (max-width: 480px) {
    .navItemLogo{
        font-size: 18px;
        padding-left: 15px;
    }
    .hamburgerMenu{
        width: auto;
        right: 0px;
        left: 0px;
        top: 52px;
    }
   .footerLogoImgSizeNavbar{
    height: 45px;
    width: 45px;
    position: absolute;
    left: -16px;
    top: -10px;
    /* margin-top: 20px; */
}
    
}
