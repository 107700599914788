*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contactUsFormContainer1{
    /* height: 800px; */
    width:auto;
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    flex-direction: column;
}
.ContactUsFormPageMainHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}
.ContactUsFormPageMainHeadingActual{
    font-size: 35px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}
.contactUsFormContainer{
    /* height: 800px; */
    width:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    
}
.WholeLeftAndRightForm{
    display: flex;
    /* border: 2px black solid; */
    width: 60%;
    margin-top: 30px;
    border-radius: 20px;
    height: 600px;
}
.newLeftSecOfContactUsForm{
    width: 50%;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-direction: column;
    /* box-sizing: content-box; */
    /* background: rgba( 236, 95, 218, 0.4 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );

border-top-left-radius: 20px;
border-bottom-left-radius: 20px;

border: 1px solid rgba( 255, 255, 255, 0.18 ); */
/* background: rgba( 24, 59, 240, 0.35 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 ); */
background: rgba( 3, 19, 103, 0.7 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-top-left-radius: 20px;
border-bottom-left-radius:20px ;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.newLeftFormHeading1{
    display: flex;
    justify-content: center;
    align-items: center;
flex-direction: column;
margin: 20px;
margin-top: 120px;
}
.newLeftFormFirstHeading{
padding: 5px;
font-size: 25px;
color:white;
font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}
.newLeftFormFirstHeadingInfo{
    padding: 5px;
    font-size: 14px;
    color: white;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}
.newLeftFirstNameHeadingInfo{
    font-size: 14px;
    color: #192551;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    padding-bottom: 5px;
}
.newInputForFirstName{
    border: none;
    border-bottom:1px solid black ;
    padding-left: 5px;
    
}
.newInputForFirstName:focus{
    border: none;
    border-bottom:1px solid black ;
    padding-left: 5px;
}
.newLeftFormFirstHeadingInfo1{
    padding: 5px;
    padding-left: 10px;
    font-size: 14px;
    color: white;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}
.newLeftFormHeading{
    display: flex;
    justify-content:center;
    align-items: center;
    margin-bottom: 20px;
}
.newLeftFormHeading2{
    display: flex;
    justify-content:center;
    align-items:flex-start;
    padding-left: 34px;
    width: 200px;
    margin-bottom: 20px;
    position: relative;

}
.locationconForContactUsForm{
position: absolute;
left: 35px;
color:white;

}
.mailIconForContactUsForm{
margin: 10px;
color: white;

}
.mailContacUsInfo{
    font-size: 14px;
    color: white;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}
.adressContacUsInfo{
    font-size: 14px;
    color: white;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    padding-left: 20px;
}
.mailIconForContactUsForm1{
    margin-right: 15px;
}

.newRightSecOfContactUsForm{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba( 255, 255, 255, 0.75 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    /* border-radius: 10px; */
    border-top-right-radius:20px;
    border-bottom-right-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.newFirstAndLastNameSec{
display: flex;
justify-content: space-between;
align-items: center;
width: 400px;
margin: 40px;

}
.newFirstAndLastNameSec1{
    display: flex;
justify-content: space-between;
align-items: center;
width: 400px;
margin: 10px;
margin-top: 50px;
}
.newFirstNameSec{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    flex-direction: column;
}
.leftInfoForForm{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contactUsFormLeftHeading{
    font-size: 25px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}

.contactUsFormLeftHeadingInfo{
    font-size: 16px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100; 
}

.firstAndLastNameDiv{
    padding-top: 50px;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.beforeInput{
    margin-right: 20px;
    font-size: 16px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}
.beforeInputLastName{
    margin-right: 20px;
    font-size: 16px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}
.firsNameAndInput{
    display: flex;
    justify-content: center;
    align-items: center;
}
.firstNameInput{
    height: 40px;
    width: 250px;
    border-radius: 5px;
    padding-left: 10px;
    margin-right: 25px;
    border-color: #192551;
    
    
}
.lastNmaeAndInput{
    display: flex;
    justify-content: center;
    align-items: center;
}
.LastNameInput{
    height: 40px;
    width: 250px;

    border-radius: 5px;
    padding-left: 10px;
    /* margin-left: 25px; */
    border-color: #192551;
}

.emailaddressContactForm{
    /* width: 100%; */
    padding-top: 25px;
    padding-bottom: 25px;
    display:flex ;
    justify-content: center;
    align-items: flex-start;
    /* position: absolute; */
    /* top: 100px; */
    /* left: 107px; */
}
.emailAndInput{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 32px;
}
.emailAdressInputContactForm{
    height: 40px;
    width: 250px;

    border-radius: 5px;
    padding-left: 10px;
    /* margin-left: 25px; */
    border-color: #192551;
    margin-right: 300px;
}
.certificationContactForm{
    padding-top: 25px;
    padding-bottom: 25px;
    display:flex ;
    justify-content: flex-start;
    align-items: flex-start;

}
.certificationAndInput{
display: flex;
justify-content: flex-start;
align-items: center;
}
.beforeInputCertification{
    font-size: 16px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}
.certificationInputContactForm{
    height: 40px;
    width: 250px;
    border-radius: 5px;
    padding-left: 10px;
    margin-right: 377px;
    border-color: #192551; 
    margin-left: 20px;
}
.timeContactForm{
    padding-top: 25px;
    padding-bottom: 25px;
    display:flex ;
    justify-content: center;
    align-items: flex-start;
}
.timeAndInput{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}
.divAboveTimeAndDateInput{
    font-size: 18px;
    color: #192551;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* position: absolute; */
    /* left: 0px; */
    /* top: -35px; */
    text-decoration: underline;
    text-align: left;
    width: 100%;
    padding-bottom: 10px;
}

.timeDatePInputAllTogetherDiv{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.beforetimeInput{
    margin-right: 20px;
    font-size: 16px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}
.timeInputContactForm{
    height: 40px;
    width: 250px;
    border-radius: 5px;
    padding-left: 10px;
    margin-right: 0px;
    border-color: #192551; 
    margin-left: 0px;
}
.dateInputContactForm{
    height: 40px;
    width: 250px;
    border-radius: 5px;
    padding-left: 10px;
    margin-right: 115px;
    border-color: #192551; 
    margin-left: 20px;
}
.educationtypeContactForm{
    padding-top: 25px;
    padding-bottom: 25px;
    display:flex ;
    justify-content: center;
    align-items: flex-start;
}
.educationtypeAndInput{
    display: flex;
    justify-content: center;
    align-items: center;   
}
.educationtypeInputContactForm{
    height: 75px;
    width: 400px;
    border-radius: 5px;
    padding-left: 10px;
    margin-right: 250px;
    border-color: #192551; 
    margin-left: 20px;
}
.beforeInputeducationtype{
    margin-right: 10px;
    font-size: 16px;
    color: #192551;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 900;
}
.submitContactForm{
/* position: absolute;
top: 200px;
left:107px; */
}

.submitButtonContactForm{
    height: 40px;
    width: 70px;
    background-color:#FC9517;
    border-color: #FC9517;
    border-radius: 10px;
    color: aliceblue;
    font-size: 14px;
    font-family: 'GFS Didot', serif;
font-family: 'Lato', sans-serif;
font-weight: 100;
margin-top: 50px;
margin-bottom: 50px;
}
.successMsgContactUs{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: 100px;
    width: 250px;
    border-radius: 5px;
    text-align: center;
    font-family: 'GFS Didot', serif;
    font-family: 'Lato', sans-serif;
font-weight: 900;
background-image: url(https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/images%2FFrame66.png?alt=media&token=b156709f-3af7-44a7-a78b-5f5484ba4f71);
color: #192551;
box-shadow: 2px 2px black

}
@media (min-width: 320px) and (max-width: 480px) {
    .contactUsFormContainer1{
        width: 100%;
    }
    .contactUsFormContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 100px;


    }
    .WholeLeftAndRightForm{
        flex-direction: column;
        width: 80%;
    }
    .newLeftSecOfContactUsForm{
        width: 100%;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0px;
        height: 300px;
    }
    .newRightSecOfContactUsForm{
        width: 100%;
        border-top-right-radius: 00px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        
    }
.newLeftFormHeading1{
    margin-top: 20px;

}
.ContactUsFormPageMainHeading{
    margin-top: 0;
}
.ContactUsFormPageMainHeadingActual{
    font-size: 25px;
}
.newFirstAndLastNameSec1{
    flex-direction: column;
    line-height: 30px;
    justify-content: space-evenly;
    padding-top: 10px;
    margin: 0px;
margin-top: 0px;
width: auto;

}
.newFirstAndLastNameSec{
    flex-direction: column;
    line-height: 30px;
    justify-content: space-evenly;
    padding-top: 10px;
    margin: 0px;
    flex-direction: column;
width: auto;
}
.newLeftFormHeading{
    margin-right: 35px;
}
.newLeftFormHeading2{
    margin-right: 35px;
}

.newLeftFirstNameHeadingInfo{
    padding-bottom: 0px;
    padding-top: 5px;
    margin-right: 35px;
}
.newLeftFormFirstHeadingInfo{
    text-align: center;
}
    .leftInfoForForm{
        width: 100%;
    }
    .contactUsFormLeftHeading{
        padding: 20px;
        margin-top: 200px;
        text-align: center;
        font-size: 22px;
    }
.contactUsFormLeftHeadingInfo{
    text-align: center;
}
.rightForm{
    width: 80%;
    margin-left: 0px;
    margin-top: 100px;
    margin-bottom: 20px;
}
.firstAndLastNameDiv{
    flex-direction: column;
    padding-bottom: 5px;
}
.firsNameAndInput{
    flex-direction: column;
}
.beforeInput{
    padding: 10px;
}
.beforeInputLastName{
    padding-top: 10px;
    padding-bottom: 10px;
}
.firstNameInput{
    margin-right: 0px;
}
.lastNmaeAndInput{
    flex-direction: column;
padding-top: 5px;
}
.emailaddressContactForm{
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}
.emailAndInput{
    flex-direction: column;
    margin-right: 0px;
}
.emailAdressInputContactForm{
    margin-right: 0px;
}
.certificationContactForm{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
}
.certificationAndInput{
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.certificationInputContactForm{
    margin-right: 0px;
    margin-left: 0px;
}
.timeContactForm{
    padding-top: 5px;
    padding-bottom: 5px;
}
.timeAndInput{

}
.divAboveTimeAndDateInput{
    text-align: center;
    width: 70%;
    padding-top: 5px;
    padding-bottom: 5px;
}
.timeDatePInputAllTogetherDiv{
    justify-content: space-evenly;
    flex-direction: column;
}
.dateInputContactForm{
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 5px;

}
.beforeInputCertification{
padding-top: 5px;
padding-bottom: 5px;
}
.beforetimeInput{
padding-top: 5px;
padding-bottom: 5px;
}
.timeInputContactForm{
    margin-bottom: 5px;
}
.educationtypeContactForm{
    padding-top: 5px;
    padding-bottom: 5px;

}
.educationtypeAndInput{
    flex-direction: column;
}
.beforeInputeducationtype{
    padding-top: 5px;
    padding-bottom: 5px;

}
.educationtypeInputContactForm{
    width: 250px;
    margin-right: 0px;
    margin-left: 0px;
}
.submitButtonContactForm{
margin-top: 25px;
margin-bottom: 25px;
}
.successMaGCintainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.successMsgContactUs{
height: 80px;
width: 150px;
/* margin-top: 20px; */
}
}