.icd102FirstFoldContainer{
    width: 100%;
    display: flex;
    height: 100%;
}

.icd102FirstFoldContainerLeft{
    width: 65%;
    height: 100%;
display: flex;
justify-content: right;
align-items: flex-end;
flex-direction: column;
}

.icd102FirstFoldContainerRight{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.icd102FirstFoldContainerRightButtonDiv{
    width: 50%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
   background-color:  #6664DA;
   color: white;
   font-family: 'Jost', sans-serif;
border-radius: 10px;
cursor: pointer;
}
.speakerReadMoreInfoButton{
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6664DA;
    box-shadow: 1px 1px#6664DA;
    border-radius: 5px;
    margin-top: 5px;
    font-weight: 900;
   font-family: 'Jost', sans-serif;
   cursor: pointer;

}
.textBelowSpeaker{
    font-size: 14px;
   font-family: 'Jost', sans-serif;
    text-align: right;
    width: 90%;
}
.icd102FirstFoldContainerRightWebinarInfo{
    width: 50%;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

   color: #6664DA;
   font-family: 'Jost', sans-serif;

}
.icd102FirstFoldContainerRightWebinarInfo1{
    width: 50%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;

   color: #6664DA;
   font-family: 'Jost', sans-serif;

}
.upcomingWebinarsRight{
   font-family: 'Jost', sans-serif;
text-align: center;
width: 100%;
font-size: 24px;
margin-top: 10px;
}
.icd102FirstFoldContainerRightWebinarInfo2{
    width: 50%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
flex-direction: column;
 
   font-family: 'Jost', sans-serif;
}
.newAapcImageWebinarInfo{
    width: 80%;
    margin: 5px;
    box-shadow:2px 2px #6664DA;
    border-radius: 5px;
}
.priceOfUpcomingWebinar{
    width:90%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.horizontalLineWebinarInfo{
    height: 0.5px;
    border-radius: 1px;
    width: 60%;
    background-color: gray;
  
}

.icd102FirstFoldContainerLeftTopImageContainer{
width: 90%;
height: 100%;
display: flex;
justify-content: left;
align-items: center;
}
.icd102FirstFoldContainerLeftTopImage{
    width: 50%;
margin-top: 20px;
margin-bottom: 20px;
}

.icd102FirstFoldContainerLeftTopRightSection{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.icd102FirstFoldContainerLeftTopRightSectioCoffeeContainer{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
    padding-top: 10px;
}

.icd102FirstFoldContainerLeftTopRightSectioCoffeeContainer1{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: right;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}
.Free1{
    width: 100%;
    height: 100%;
    font-size: 28px;
    font-family: 'Jost', sans-serif;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 10px;
}

.icd102FirstFoldContainerLeftTopRightSectioCoffeeContainerImage{
    width: 20%;
    border-radius: 5px;

}
.icd102FirstFoldContainerLeftTopRightSectioCoffeeContainerImage1{
    width: 30%;
    border-radius: 5px;

}

.aboveFree{
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-family: 'Jost', sans-serif;
color: grey;
margin-top: 10px;
    text-align: right;
    
    }