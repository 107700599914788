*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.privacyPolicyPageContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.privacyPolicy{
    width: 70%;
    text-align: left;
    font-size: 25px;
    font-family: 'GFS Didot', serif;
        font-family: 'Lato', sans-serif;
        font-weight: 900;
     color: #00254F;
     margin: 20px;
}
.privacyPolciySubHeadingFirst{
    width: 70%;
    text-align: left;
    font-size: 16px;
    font-family: 'GFS Didot', serif;
        font-family: 'Lato', sans-serif;
        font-weight: 900;
     color: #00254F;
     margin-top: 20px;
}
.privacyPolciySubHeadingFirstInfo{
    width: 70%;
    text-align: left;
    font-size: 14px;
    font-family: 'GFS Didot', serif;
        font-family: 'Lato', sans-serif;
        font-weight: 100;
     color: #00254F;
     margin-top: 20px;
}
