* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.WebinarContainer {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
}
.sdohbackgroundImageConatiner{
	width:100%;
	height:100%;
	
}
.WebinarContentWrapper {
	height: 100%;
	overflow-y: scroll;
}
.WholeSectionAboveFooterContainer {
	width: 100%;
	height: 100vh;

	display: flex;
}

.modalWrapper {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: rgba(189, 189, 189, 0.9);
	z-index: 100;
}


.WebinarFullDetailsColumnLeft {
	width: 64%;
	height: 100%;
	display: flex;
	justify-content: right;
	align-items: center;
	/* overflow-y: hidden; Add this to enable scrolling when content overflows */
}

.WebinarBuyingInfoContainer {
	width: 30%;
	height: 100vh;
	/* position: sticky; */
	display: flex;
	justify-content: start;
	align-items:start;
	flex-direction: column;
	/* top: 0; */


	/* background: linear-gradient(90deg, rgba(222,91,175,1) 0%, rgba(249,242,246,1) 81%); */
	

	
}

.addToWishListDiv {
	width: 100%;
	height: 60px;
	display: flex;
	align-items: center;
	background: rgba(254, 253, 255, 1);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	padding-left: 20px;
}
.WebinarBuyingInfoContainerDetails {
	margin-top: 20px;
	width: 100%;
	height: 50%;
	background-color: white;
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	padding: 20px;
}
.caitlinWhamSpeakerImage{
	width:110%;
	margin-top:50px;
}
.caitlinWhamSpeakerImageInfo{
	background: rgba( 255, 255, 255, 0 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 20px );
-webkit-backdrop-filter: blur( 20px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
width:110%;
height:100%;
}
.caitlinWhamSpeakerImageInfoHeading{
color:white;
width:100%;
height:100%;
text-align:center;
font-size:64px;
margin-top:20px;
font-family: 'Concert One', cursive;

}
.WebinarBuyingInfoContainerDetailsICD10 {
	margin-top: 10px;
	width: 100%;
	height: 45%;
	background-color: white;
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	padding: 10px;

}
.WebinarBuyingInfoContainerDetailsICD10Coffee {

	width: 100%;
	height: 30%;
	background: rgb(255, 255, 255);
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 20px );
	-webkit-backdrop-filter: blur( 20px );
	border-radius: 10px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
	padding: 10px;
	display:flex;
align-items:center;
flex-direction: column;

}
.WebinarBuyingInfoContainerDetailsICD10Coffee {

	width: 100%;
	height: 30%;
	background: rgb(255, 255, 255);
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 20px );
	-webkit-backdrop-filter: blur( 20px );
	border-radius: 10px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
	padding: 10px;
	display:flex;
align-items:center;
flex-direction: column;

}
.WebinarBuyingInfoContainerDetailsICD10Coffee2 {

	width: 100%;
	height: 22%;
	background: rgb(255, 255, 255);
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 20px );
	-webkit-backdrop-filter: blur( 20px );
	border-radius: 10px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
	padding: 10px;
	margin-top: 10px;
	display:flex;
align-items:center;
flex-direction: column;

}
.WebinarBuyingInfoContainerDetailsICD10CoffeeH1{
	font-size:24px;
	text-align:center;
	width: 100%;
	font-family: 'Concert One', cursive;
height: 100%;
	font-weight:900;
	
}
.WebinarBuyingInfoContainerDetailsICD10Coffeebelowp{
	font-size:32px;
	text-align:center;
	width: 100%;
	font-family: 'Concert One', cursive;
height: 100%;
margin-top:5px;
	font-weight:900;
	
}
.WebinarBuyingInfoContainerDetailsICD10CoffeeIconContainer{
	width:100%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content: center;




}
.WebinarBuyingInfoContainerDetailsICD10CoffeeIconContainerFreeImageDiv{
	width: 60%;
	height:100%;
	
}

.WebinarBuyingInfoContainerDetailsICD10CoffeeIconContainerFreeImage{
	width: 65%;
    height: 80%;
    margin-left: 30px;
    margin-top: 20px;

}


.WebinarBuyingInfoContainerDetailsSpeaker {
	margin-top: 10px;
	width: 100%;
	height: 30%;
	background: rgba(254, 253, 255, 1);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.WebinarBuyingInfoContainerDetailsSpeakerICD10 {
	margin-top: 10px;
	width: 51%;
	height: 30%;
	background: rgba( 255, 255, 255, 0 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 0px );
-webkit-backdrop-filter: blur( 0px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.WebinarBuyingInfoContainerDetailsSpeakerFirstFold {
	display: flex;
	width: 100%;
	height: 70%;
}
.SpeakerdeatilsInfo {
	width: 100%;
	height: 100%;
	padding-top: 20px;
	display: flex;
	justify-content: center;
}
.SpeakerdeatilsInfoP {
	font-size: 14px;
	font-family: 'Ysabeau Office', sans-serif;
	line-height: 22px;
	text-align: left;
	color: #890389;
	width: 90%;
}
.WebinarBuyingInfoContainerDetailsBuyButton {
	width: 100%;
	height: 20%;
	background-color: #840a83;
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);

	border-radius: 10px;
	display: flex;
	position: relative;
}
.WebinarBuyingInfoContainerDetailsBuyButtonICD10Div {
	width: 100%;
	height: 100%;
	
margin-top:13px;
	
	display: flex;
	justify-content:center;
	align-items:center;
}

.WebinarBuyingInfoContainerDetailsBuyButtonICD10Button{
	width: 122px;
    height: 55px;
    border-radius: 5px;
    background-color: #008327;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    font-family: 'Ysabeau Office', sans-serif;
    font-size: 23px;
    border-radius: 10px;
    color: white;
}

.WebinarBuyingInfoContainerDetaiclsBuyButtonP {
	font-family: 'Ysabeau Office', sans-serif;
	/* padding-left: 10px; */
	font-weight: 900;
	font-size: 28px;
	color: rgba(254, 253, 255, 1);
	text-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	width: 70%;
	text-align: center;
	height: 100%;
	display: flex;
	/* justify-content: center; */
	padding-left: 10px;
	align-items: center;
}
.WebinarBuyingInfoContainerDetaiclsBuyButtonPriceP {
	font-family: 'Ysabeau Office', sans-serif;
	/* padding-left: 10px; */
	font-size: 28px;
	color: rgba(254, 253, 255, 1);
	text-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	width: 30%;
	text-align: center;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	
}
.WebinarBuyingInfoContainerDetaiclsBuyButtonPricePICD10 {
	font-family: 'Ysabeau Office', sans-serif;
	/* padding-left: 10px; */
	font-size: 64px;
	color: rgb(0, 0, 0);
	text-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	width: 100%;
	text-align: center;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	
}
.WebinarBuyingInfoContainerDetaiclsBuyButtonPriceUpperHeading {
	position: absolute;
	font-size: 12px;
	font-family: 'Ysabeau Office', sans-serif;
	right: 26px;
	top: 2px;
	color: white;
	font-weight: 900;
}

.WebinarBuyingInfoContainerDetailsPart2Div {
	width: 100%;
	height: 70%;

	display: flex;
	flex-direction: column;
}
.WebinarBuyingInfoContainerDetailsPart2DivRow {
	padding: 20px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 55px;
}
.WebinarBuyingInfoContainerDetailsPart2DivRowICD10{
	display: flex;
	padding: 5px;
	justify-content: center;
	align-items: center;
}
.WebinarBuyingInfoContainerDetailsPart2DivRowP {
	font-size: 21px;
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 900;
}

.WebinarBuyingInfoContainerDetailsPart2DivRowPICD10{
	font-size: 45px;
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 900;
}

.WebinarBuyingInfoContainerDetailsPart2DivRowSpan {
	font-size: 21px;
	font-family: 'Ysabeau Office', sans-serif;
	padding-left: 90px;
}
.WebinarBuyingInfoContainerDetailsPart2DivRowSpan2 {
	font-size: 21px;
	font-family: 'Ysabeau Office', sans-serif;
	padding-left: 122px;
}
.addToWishListIcon {
	font-size: 32px;
	padding-right: 20px;
	width: 30%;
	color: #840a83;
}

.addToWishListLine {
	font-size: 21px;
	font-family: 'Ysabeau Office', sans-serif;
	padding-left: 10px;
	font-weight: 900;
}
/* Hide the scrollbar */
/* Hide the scrollbar */
.WebinarContentWrapper::-webkit-scrollbar {
	display: none;
}

/* .WebinarFullDetailsColumnLeft::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .WebinarFullDetailsColumnLeft::-webkit-scrollbar-thumb {
    background-color: transparent;
  } */
.navbarContainerWebinarPage {
	height: 150px;
	width: 100%;
}
.sessionHighlights {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 90%;
	margin-top: 20px;
}

.sessionHighlightsDivHeading {
	height: 12%;
	width: 100%;
}

.sessionHighlightsInfo {
	height: 50%;
	width: 100%;
	display: flex;
	padding-bottom: 25px;
}

.left {
	width: 36%;
	background: rgba(240, 158, 92, 1);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 100px;
	height: 100%;
}

.highlightImage {
	height: 90%;
	width: 80%;
	border-radius: 10px;

	background: rgba(255, 255, 255, 0.2);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}

.right {
	width: 86%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.row {
	display: flex;
	align-items: center;
	justify-content: left;
	padding-left: 77px;
	margin-bottom: 5px;
	margin-top: 5px;
	font-family: 'Ysabeau Office', sans-serif;
	color: #7f3f62;
	font-size: 24px;
}
.rowICD {
	display: flex;
	align-items: flex-start;
	justify-content: left;
	flex-direction: column;

	margin-bottom: 5px;
	margin-top: 5px;
	font-family: 'Ysabeau Office', sans-serif;
	color: #000000;
	font-size: 18px;
	/* list-style-type: square; */
	list-style: disc;
	width: 100%;
}
.DiveAboveSessionHighlights {
	width: 90%;
	height: 100%;
	/* padding: 20px; */
}
.DiveAboveSessionHighlightsP {
	width: 100%;
    height: 100%;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    /* padding-left: 116px; */
    /* padding: 10px 77px; */
    color: black;
}

.imageButton {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #000;
	margin-right: 10px;
}

.imageButton:focus {
	outline: none;
}

p {
	margin: 0;
}

.firstFoldWebinarContainer {
	display: flex;
	width: 100%;
	height: 100%;
	/* padding-top: 20px; */
	padding-bottom: 20px;
	background-image: url("https://firebasestorage.googleapis.com/v0/b/lmsrcm-438b0.appspot.com/o/SDOH%2FSdoh%20Backgound%20Image2.png?alt=media&token=cc751452-2a43-4bf8-ae82-df63efd60b8b");
    background-position: center;
    background-repeat: no-repeat; 
	background-size:cover;
	border: 1px solid rgba(255, 255, 255, 0.18);
	justify-content: center;
	align-items: center;
	/* margin-top: 80px; */
}
.ButtonAndDateContainer {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	height: 100%;
}
.StripeQRCodeContainer {
	position: absolute;
	height: 150px;
	width: 150px;
	border-radius: 10px;
	bottom: -8px;
	right: 130px;
	/* background: rgb(242, 221, 242); */
	background: rgba(255, 255, 255, 0.1);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	display: flex;
	justify-content: center;
	align-items: center;
}

.firstFoldInfoContainerfirstLastparaICD10{
	font-family: 'Ysabeau Office', sans-serif;
	/* width: 50%; */
	text-align: left;
	
	/* margin-bottom: 50px; */
	font-size: 24px;
	/* margin-left: 10px; */

	color: rgb(5, 3, 71);
	font-style: italic;
position:absolute;
bottom:50px;
right:11px;
	
	display: flex;
	justify-content: center;
	align-items: center;
	/* align-self: center; */
}
.StripeQRCodeContainerImg {
	height: 120px;
	width: 120px;
	border-radius: 10px;
}
.firstFoldWebinarContainerLeftImage {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60%;
	height: 100%;
	perspective: 1000px;
}

.image {
	width: 400px;
	/* height: 400px; Adjust the size of the image as needed */
	transform: translateZ(-10px) scale(1); /* Adjust the translateZ and scale values for desired effect */
	box-shadow: 0 10px 10px rgba(163, 163, 163, 0.4);
	background: rgba(245, 248, 250, 0.8);
	box-shadow: 0 32px 32px 0 rgba(7, 7, 7, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	justify-content: center;
	align-items: center;
}
.firstFoldInfoContainer {
	display: flex;
	width: 88%;

	height: 630px;
	flex-direction: column;
	padding-top: 20px;
	padding-left:108px;
	position: relative;
}
.firstFoldWebinarContainerLeftAndRight{
	width:100%;
	height:100%;
	display:flex;
	background: rgba( 255, 255, 255, 0 );
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 0px );
	-webkit-backdrop-filter: blur( 0px );
	border-radius: 10px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
	border-radius: 10px;
	justify-content: center;
}

	
.firstFoldInfoContainerHeading {
	font-family: 'Ysabeau Office', sans-serif;
	width: 100%;
	text-align: left;
	font-size: 100px;
	color: #0c0c0c;
	text-shadow: 7px 5px #840a83;
	font-weight: 600;
	margin-top: 20px;
}

.firstFoldInfoContainerTagline {
	font-family: 'Ysabeau Office', sans-serif;
	font-size: 54px;
	margin-left: 10px;
	/* color: rgb(84, 84, 84); */
	color: #111212;
	text-shadow: 1.5px 1.5px #840a83;
	width: 96%;
	text-align: left;
}
.firstFoldInfoContainerfirstpara {
	font-family: 'Ysabeau Office', sans-serif;
	width: 100%;
	text-align: left;
	font-size: 16px;
	margin-left: 10px;
	width: 80%;
	margin-top: 20px;
	color: rgb(9, 9, 9);
}
.firstFoldInfoContainerAAPCImageDiv {
	width: 100%;
	height: 30%;
	display: flex;
	justify-content: left;
	align-items: center;
}
.firstFoldInfoContainerAAPCImage {
	width: 25%;
	height: 40%;
	border-radius: 5px;
}
.firstFoldInfoContainerfirstLastpara {
	font-family: 'Ysabeau Office', sans-serif;
	/* width: 50%; */
	text-align: left;

	/* margin-bottom: 50px; */
	font-size: 24px;
	/* margin-left: 10px; */
	width: 65%;
	margin-top: 5px;
	color: rgb(5, 3, 71);
	font-style: italic;

	height: 60px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* align-self: center; */
}
.ThirdFoldContainer{
	width:100%;
	height:100%;
	display:flex;
	justify-content:center;
	align-items:center;
}
.ThirdFold{
	width:90%;
}
.firstFoldInfoContainerfirstLastparaCDINurses {
	font-family: 'Ysabeau Office', sans-serif;
	/* width: 50%; */
	text-align: left;
	margin-right: 50px;
	/* margin-bottom: 50px; */
	font-size: 24px;
	/* margin-left: 10px; */
	width: 50%;
	margin-top: 90px;
	color: rgb(5, 3, 71);
	font-style: italic;

	height: 60px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* align-self: center; */
}
.firstFoldInfoContainerButtonDiv {
	width: 20%;
	display: flex;
	margin-top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 30px;
}
.firstFoldInfoContainerButtonDivICD10 {
	width: 100%;
	display: flex;
	margin-top: 15px;
	display: flex;
	justify-content: center;
	align-items: left;
	flex-direction: column;

}
.firstFoldInfoContainerButtonDivICD10ButtonAndAAPC{
	width:100%;
	height:70%;
	display:flex;
justify-content: left;
	align-items:center;
}
.SecondFoldBothInfoAndInputConatiner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	flex-direction: column;
}
.fifthFoldInfoContainerButtonDiv {
	width: 100%;
	display: flex;
	margin-top: 20px;
	justify-content: center;
	align-items: center;
}

.firstFoldInfoContainerButton {
	width: 150px;
	height: 40px;
	border-radius: 5px;
	box-shadow: 2px 2px rgb(84, 84, 84);
	background-color: rgb(245, 5, 117);
	color: white;
	margin-left: 10px;
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 700;
}
.fifthFoldInfoContainerButton {
	width: 150px;
	height: 50px;
	border-radius: 5px;
	box-shadow: 3px 3px rgb(124, 200, 235);
	transition: 0.7s;
	background-color: rgb(249, 249, 249);
	border: 1px solid #7e4163;
	border-right: 1px solid #7e4163;

	color: #7e4163;
	/* margin-left: 10px; */
	font-size: 18px;
	/* border: 1px solid white; */
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 700;
}
.fifthFoldInfoContainerButton:hover {
	width: 150px;
	height: 50px;
	border-radius: 5px;
	box-shadow: 4px 4px rgb(236, 119, 212);
	background-color: rgb(249, 249, 249);
	color: rgb(2, 55, 249);
	/* margin-left: 10px; */
	font-size: 18px;
	border: 1px solid white;
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 700;
}
.fifthFoldInfoContainerButton1 {
	width: 150px;
	height: 50px;
	border-radius: 5px;
	box-shadow: 3px 3px rgb(124, 200, 235);
	transition: 0.7s;
	background-color: #0f9e07;
    border: 1px solid #7e4163;
    border-right: 1px solid #7e4163;
    color: #f8f5f7;
	/* margin-left: 10px; */
	font-size: 18px;
	/* border: 1px solid white; */
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 700;
	cursor: pointer;
}
.fifthFoldInfoContainerButton11{
	width: 150px;
	height: 50px;
	border-radius: 5px;
	box-shadow: 3px 3px rgb(124, 200, 235);
	transition: 0.7s;
	background-color: #fdfefd;
    border: 1px solid #7e4163;
    border-right: 1px solid #7e4163;
    color: #3a0d2b;
	/* margin-left: 10px; */
	font-size: 18px;
	/* border: 1px solid white; */
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 700;
	cursor: pointer;

}
.fifthFoldInfoContainerButton11:hover {
	width: 150px;
	height: 50px;
	border-radius: 5px;
	box-shadow: 4px 4px rgb(148, 119, 236);
	background-color: rgb(205, 81, 196);
	color: rgb(1, 1, 15);
	/* margin-left: 10px; */
	font-size: 18px;
	border: 1px solid rgb(112, 112, 112);
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 700;
}
.fifthFoldInfoContainerButton1ICD10{
	width: 150px;
	height: 50px;
	border-radius: 5px;
	box-shadow: 3px 3px rgb(124, 200, 235);
	transition: 0.7s;
	background-color: rgb(249, 249, 249);
	border: 1px solid #7e4163;
	border-right: 1px solid #7e4163;
margin-right:50px;
	color: #7e4163;
	margin-left: 10px;
	font-size: 18px;
	/* border: 1px solid white; */
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 700;
}

.fifthFoldInfoContainerButton1Link {
	text-decoration: none;

	color: #7e4163;
	/* margin-left: 10px; */
}
.fifthFoldInfoContainerButton1Link1{
	text-decoration: none;

	color: white;
}
.fifthFoldInfoContainerButton1LinkBook {
	text-decoration: none;

	color: white;
	/* margin-left: 10px; */
}
.fifthFoldInfoContainerButton1Link2 {
	color: white;
	text-decoration: none;
}
.fifthFoldInfoContainerButton1Link2{
	color:white;
	text-decoration: none;

}
.fifthFoldInfoContainerButton1Link3{
	color: #7e4163;
	text-decoration: none;

}
.fifthFoldInfoContainerButton1:hover {
	width: 150px;
	height: 50px;
	border-radius: 5px;
	box-shadow: 4px 4px rgb(148, 119, 236);
	background-color: rgb(205, 81, 196);
	color: rgb(1, 1, 15);
	/* margin-left: 10px; */
	font-size: 18px;
	border: 1px solid rgb(112, 112, 112);
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 700;
}

.speakerContainerDetails {
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	/* padding: 20px; */
}
.speakerContainerDetailsHeadingDiv {
	width: 100%;
	height: 100px;
}

.speakerContainerDetailsHeading {
	font-size: 28px;
	font-family: 'Playfair Display', serif;
}
.speakerContainerDetailsHeadingreadMore {
	font-size: 21px;
	margin-top:6px;
	font-family: 'Playfair Display', serif;
	cursor: pointer;
	text-decoration: underline rgb(87, 21, 240);
	color: rgb(87, 21, 240);
}
.speakerContainerDetailsHeadingCertification {
	padding-top: 10px;
	font-size: 25px;
	font-family: 'Playfair Display', serif;
	color: #323131;
}
.speakerImageContainer {
	display: flex;
	justify-content: start;
	flex-direction: column;
	width: 50%;
}
.speakerImage {
	width: 80%;
	height: 100%;
	border-radius: 10px;
	margin-left: 40px;
	/* margin-bottom: 20px; */
}
.WebinarBuyingInfoContainerWrapper {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	padding: 20px;
}
.firstFoldInfoContainerButton2 {
	width: 120px;
	height: 40px;
	border-radius: 5px;
	box-shadow: 2px 2px rgb(84, 84, 84);
	background-color: rgb(105, 176, 234);
	color: white;
	margin-left: 10px;
	font-family: 'Ysabeau Office', sans-serif;
	font-weight: 700;
}

.secondFoldWebinarContainer {
	display: flex;

	height: 150px;
	/* margin-top: 20px; */
	width: 100%;
	background-color: #323131;
}

.secondFoldWebinarContainerInputTextDiv {
	width: 50%;
	height: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding-left: 15px; */
	margin-top: 10px;
}

.secondFoldWebinarContainerInputText {
	height: 40px;
	width: 40%;
	border-radius: 5px;
	padding-left: 5px;
}

.secondFoldWebinarContainerInfo {
	width: 100%;
	height: 30%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	/* padding-right: 15px; */
}
.secondFoldWebinarContainerInfoTagline {
	font-family: 'Ysabeau Office', sans-serif;
	font-size: 18px;
	margin-left: 10px;
	color: rgb(252, 248, 248);
}
.secondFoldWebinarContainerInfoTaglineImportant {
	font-family: 'Ysabeau Office', sans-serif;
	font-size: 20px;
	margin-left: 10px;
	color: rgb(166, 124, 232);
	font-weight: 800;
}

.thirdFoldWebinarContainer {
	width: 100%;
	height: 100%;
	/* display: flex; */
	background-color: white;
	color: #333;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.thirdFoldWebinarContainerLeftdiv {
	width: 33%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 700px;
}

.thirdFoldWebinarContainerCenterdiv {
	width: 33%;
	height: 100%;
	display: flex;
	flex-direction: column;
	height: 500px;
}
.questions {
	font-size: 32px;
}
.thirdFoldWebinarContainerCenterdivAboveP {
	width: 100%;
	text-align: left;
	
	font-family: 'Jost', sans-serif;
	font-size: 32px;
	
	color: #FF5757;
}
.thirdFoldWebinarContainerCenterdivAbovePP {
	width: 100%;
	text-align: left;
	margin-top: 1.5rem;
	font-family: 'Jost', sans-serif;

	font-size: 32px;
	color: #FF5757;

}
.container {
	max-width: 800px;
	padding: 2rem;
	background: rgba(255, 255, 255, 0.12);
	border-radius: 12px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.header {
	text-align: center;
	margin-bottom: 2rem;
}

.header h1 {
	font-size: 2.5rem;
	margin-bottom: 0.5rem;
}

.header p {
	font-size: 1.2rem;
	color: #888;
}

.highlight-box {
	background: rgba(255, 255, 255, 0.08);
	padding: 1.5rem;
	border-radius: 12px;
	margin-bottom: 2rem;
}

.highlight-box h2 {
	font-size: 1.8rem;
	margin-bottom: 1rem;
}

.session-list {
	list-style-type: decimal;
	margin-left: 2rem;
	font-size: 1.1rem;
	color: #666;
}

.cta-button {
	display: inline-block;
	background-color: #007bff;
	color: #fff;
	padding: 1rem 2.5rem;
	text-align: center;
	text-decoration: none;
	border-radius: 6px;
	font-weight: bold;
	font-size: 1.2rem;
	transition: background-color 0.3s ease;
	margin-top: 20px;
}

.cta-button:hover {
	background-color: #0056b3;
}
.thirdFoldWebinarContainerCenterdivImage {
	margin-bottom: 50px;
}

.thirdFoldWebinarContainerRightdiv {
	width: 33%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 700px;
}

.thirdFoldWebinarContainerLeftdivAboveP {
	width: 260px;
	height: 150px;
	text-align: center;
	margin-bottom: 60px;
	font-family: 'Ysabeau Office', sans-serif;
	background: rgba(207, 197, 221, 0.05);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	font-size: 16px;
	font-weight: 100;
	padding-top: 20px;
	padding-left: 30px;
	padding-right: 30px;
	flex-direction: column;
	display: flex;
}

.IconContainerForQuestions {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 10px;
}
.thirdFoldWebinarContainerLeftdivBelowP {
	width: 260px;
	height: 150px;
	text-align: center;
	margin-bottom: 60px;
	font-family: 'Ysabeau Office', sans-serif;
	background: rgba(207, 197, 221, 0.05);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	font-size: 16px;
	font-weight: 100;
	padding-top: 20px;
	padding-left: 30px;
	padding-right: 30px;
	flex-direction: column;
	display: flex;
}

.FourthFoldWebinarContainer {
	width: 100%;

	display: flex;
	flex-direction: column;
}

.ModalContainer {
	width: 60%;
	height: 80%;
	top: 20%;
	left: 31%;
	transform: translate(-20%, -12%);
	position: fixed;
	background-color: white;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	padding-bottom: 20px;
}
.trainingAgendaContainer {
	width: 80%;
	margin: 20px;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
}

.FourthFoldWebinarContainerHeadingDiv {
	margin-top: 40px;
	margin-bottom: 20px;
	text-align: left;
	padding-left: 75px;
}
.SixthFoldWebinarContainerHeadingDiv {
}
.SixthFoldWebinarContainerHeadingDiv {
	/* margin-top: 40px; */
	/* margin-bottom: 40px; */
	/* text-align: center; */
	padding-right: 50px;
	background-color: #dfdfdf;
	width: 80%;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.faqDIV {
	width: 100%;
	height: 70%;
	display: flex;
}
.faqDIVLeft {
	width: 100%;
	height: 100%;
}
.faqDIVLeftRow {
	display: flex;
	width: 100%;
	height: auto;
	margin-top: 20px;
	margin-bottom: 20px;
}

.faqDIVRight {
	width: 0%;
	height: 100%;
}
.faqDIVLeftRowIconDiv {
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
	border: 2px solid black;
	margin-left: 75px;
}

.faqDIVLeftRowInfoDiv {
	width: 85%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.outlineDIVLeftRowInfoDiv {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.faqDIVLeftRowInfoHeadingDiv {
	width: 100%;
	height: 15%;
}
.outlineDIVLeftRowInfoHeadingDiv {
	width: 100%;
	height: 15%;
	text-align: left;
	padding-left: 100px;
}

.faqDIVLeftRowInfoHeading {
	font-family: 'Dosis', sans-serif;
	padding-left: 20px;
	font-size: 20px;
}
.outlineDIVLeftRowInfoHeading {
	font-family: 'Dosis', sans-serif;
	padding-right: 20px;
	font-size: 24px;
}
.faqDIVLeftRowInfoDivInfoDiv {
	width: 100%;
	height: 85%;
	margin-top: 20px;
}
.outlineDIVLeftRowInfoDivInfoDiv {
	width: 100%;
	height: 85%;
	margin-top: 20px;
	text-align: left;
}

.faqDIVLeftRowInfoDivInfoP {
	padding-left: 20px;
	font-family: 'Dosis', sans-serif;
	width: 100%;
	font-size: 20px;
	color: #807e7e;
	line-height: 25px;
}
.outlineDIVLeftRowInfoDivInfoP {
	padding-left: 100px;
	font-family: 'Dosis', sans-serif;
	width: 89%;
	font-size: 20px;
	color: #807e7e;
	line-height: 35px;
}

.faqDIVLeftRowIcon {
	font-size: 32px;
	color: rgb(89, 118, 235);
}
.FourthFoldWebinarContainerHeading {
}
.FourthFoldWebinarContainerHeadingFAQ {
	font-family: 'Dosis', sans-serif;
	font-size: 48px;
	color: #6664DA;
}
.FourthFoldWebinarContainerHeadingTagline {
	font-family: 'Dosis', sans-serif;
	font-size: 14px;
}

.FourthFoldWebinarContainerHeadingTaglineLink {
	text-decoration: underline;
	color: rgb(97, 5, 245);
}

.FifthFdoldWebinarContainer {
	width: 100%;
	height: auto;
	background-color: #7e4163;
padding-top: 8px;
	padding-bottom: 27px;
}

.FifthFdoldWebinarContainerRow {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.FifthFdoldWebinarContainerRowP {
	text-align: center;
	color: white;
	font-size: 48px;
	font-family: 'Dosis', sans-serif;
	padding: 20px;
	text-shadow: 1px 1px #050505;
	width: 80%;
}

.SixthFoldWebinarContainerHeadingFAQ {
	font-family: 'Dosis', sans-serif;
	font-size: 48px;
	color: rgb(3, 3, 3);
}
.footerContainerWebinarContainer {
	margin-top: 50px;
}

.thirdFoldWebinarContainerCenterdivBelowPContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.thirdFoldWebinarContainerCenterdivBelowP {
	width: 300px;
	height: 150px;
	text-align: center;
	margin-bottom: 40px;
	font-family: 'Ysabeau Office', sans-serif;
	background: rgba(207, 197, 221, 0.05);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	font-size: 16px;
	font-weight: 100;
	padding-top: 20px;
	padding-left: 30px;
	padding-right: 30px;
	flex-direction: column;
	display: flex;
}
.kababmehaddi {
	margin-top: 55px;
	margin-bottom: 45px;
}

@media screen and (max-width: 1100px) {
	.secondFoldWebinarContainerInfoTagline{
margin-left: 0px;
padding: 10px;
text-align: center;
	}
	.secondFoldWebinarContainerInputTextDiv{
width: 100%;
	}
	.WebinarBuyingInfoContainerDetailsPart2DivRow {
		padding-left: 0;
	}
	.firstFoldInfoContainerTagline {
		font-size: 34px;
	}
	.footerLogoMenu {
		margin-left: 0;
	}
	.kababmehaddi {
		margin-top: 0;
		margin-bottom: 0;
	}
}
@media screen and (max-width: 600px) {
	.thirdFoldWebinarContainer {
		width: unset;
		height: unset;
		display: block;
	}
	.firstFoldInfoContainer {
		width: 100%;
		padding-left: 0px;
	}
	.firstFoldWebinarContainer {
		flex-wrap: wrap;
	}
	.WebinarFullDetailsColumnLeft {
		width: 100%;
	}
	.WebinarBuyingInfoContainer {
		width: 100%;
	}
	.WebinarBuyingInfoContainerDetailsPart2DivRow {
		padding-left: 0;
	}
	.DiveAboveSessionHighlightsP {
	padding: 25px;
	}
	.FourthFoldWebinarContainerHeadingDiv {
		padding-left: 0px;
		text-align: center;
	}
	.faqDIVLeftRow{
		padding-left: 15px;
	}
	.faqDIVLeftRowIconDiv {
		margin-left: 10px;
	}
	.faqDIV {
		width: 100%;
	}
	.right{
		width: 100%;
	}
	.row{
		padding-left: 0px;
	}
	.rowICD{
		padding-left:20px;
	}
	.sessionHighlights{
		padding: 20px;
	}
	.faqDIVLeftRowInfoDivInfoP{
		width: 90%;
	}
	.thirdFoldWebinarContainerCenterdivAboveP {
		padding-left: 0px;
	}
	.firstFoldInfoContainerfirstLastpara {
		font-size: 18px;
	}
}